$background-color: #343434;
$elements-background-color: #242424;
$from-color: #f6b75e;
$to-color: #e94173;
$from-color-success: #52f262;
$to-color-success: #0799b9;
$from-color-danger: #f252c5;
$to-color-danger: #cf2846;
$from-color-info: #52e9f2;
$to-color-info: #8007b9;
$text-color: #ecf0f1;

// LIGHT THEME VARS
// $background-color: #ecf0f1;
// $elements-background-color: #ffffff;
// $from-color: #f6b75e;
// $to-color: #e94173;
// $from-color-success: #52f262;
// $to-color-success: #0799b9;
// $from-color-danger: #f252c5;
// $to-color-danger: #cf2846;
// $from-color-info: #52e9f2;
// $to-color-info: #8007b9;
// $text-color: #535353;

/**
 * RESETS AND VAR-INDEPENDENT CSS
 */
html,
body {
  margin: 0;
  font-family: "Varela Round", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
#root {
  min-height: 100%;
  border: 1px solid transparent;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Varela Round", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}
code {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.container {
  max-width: 500px;
  padding: 0 25px;
  margin: 50px auto 25px;
}

.logo {
  user-select: none;
  padding: 0 25px;
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 250px;
  }
}
/**
 * END // RESETS AND VAR-INDEPENDENT CSS
 */

html,
body {
  background-color: $background-color;
}

* {
  color: $text-color;
}

.input-group {
  $border-size: 2px;
  $border-radius: 8px;
  position: relative;
  display: flex;
  width: 100%;
  padding: $border-size;
  overflow: hidden;
  border-radius: $border-radius;

  z-index: 2;

  background: linear-gradient(to right bottom, $from-color, $to-color);

  box-shadow: 0 0 0 0 rgba($from-color, 0.4);
  transition: box-shadow 150ms ease-in-out;

  &.info {
    background: linear-gradient(
      to right bottom,
      $from-color-info,
      $to-color-info
    );
    box-shadow: 0 0 0 0 rgba($from-color-info, 0.25);
    &:focus-within {
      box-shadow: 0 0 0 4px rgba($from-color-info, 0.25);
    }
    button {
      &:after {
        background: linear-gradient(
          to right bottom,
          $from-color-info,
          $to-color-info
        );
      }

      &:active:after {
        transform: scale3d(3, 3, 1);

        box-shadow: 0 0 0 3px rgba($from-color-info, 0.05),
          0 0 0 6px rgba($from-color-info, 0.04), 0 0 0 10px rgba($from-color-info, 0.03),
          0 0 0 16px rgba($from-color-info, 0.03);
      }
    }
  }

  :first-child {
    border-top-left-radius: $border-radius - $border-size;
    border-bottom-left-radius: $border-radius - $border-size;
  }
  :last-child {
    border-top-right-radius: $border-radius - $border-size;
    border-bottom-right-radius: $border-radius - $border-size;
  }

  &:focus-within {
    box-shadow: 0 0 0 4px rgba($from-color, 0.4);

    label {
      color: $text-color;
    }
  }

  label {
    width: 46px;
    height: 46px;
    background-color: $elements-background-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($text-color, 0.5);
    transition: color 150ms ease-in-out;
    i {
      color: inherit;
    }
  }
  input {
    max-width: unset;
    flex: 1;
    border: none;
    background-color: $elements-background-color;
    outline: none;
  }
  button {
    position: relative;
    min-width: 46px;
    height: 46px;
    background-color: $elements-background-color;
    color: $text-color;
    transition: color 150ms ease-in-out;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    z-index: 0;
    i {
      color: inherit;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale3d(0, 0, 1);

      margin-top: -18px;
      margin-left: -18px;

      opacity: 0;
      transition: transform 200ms ease-in-out, opacity 150ms ease-in-out;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: linear-gradient(to right bottom, $from-color, $to-color);
      z-index: -1;
    }

    &:hover,
    &:active,
    &:focus {
      color: $elements-background-color;
      &:after {
        transform: scale3d(1, 1, 1);
        opacity: 1;
      }
    }
    &:active:after {
      transform: scale3d(3, 3, 1);

      box-shadow: 0 0 0 3px rgba($from-color, 0.05),
        0 0 0 6px rgba($from-color, 0.04), 0 0 0 10px rgba($from-color, 0.03),
        0 0 0 16px rgba($from-color, 0.03);
    }
  }
}

.has-suggestions {
  position: relative;
  z-index: 3;
  .suggestions-box {
    display: none;
    position: absolute;
    width: 100%;
    box-shadow: 0 5px 10px 4px rgba($from-color, 0.3);
  }
  .suggestions-box-trigger {
    z-index: 1;
    &:focus-within + .suggestions-box {
      display: block;
      padding: 24px 16px 16px;
      margin-top: -6px;
      background-color: $elements-background-color;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.btn {
  &.success {
    box-shadow: 0 0 0 0 rgba($from-color-success, 0.4);
    background: linear-gradient(
      to right bottom,
      $from-color-success,
      $to-color-success
    );
    &:focus {
      box-shadow: 0 0 0 4px rgba($from-color-success, 0.4);
    }
    .material-icons-round:after,
    &.round:active {
      box-shadow: 0 0 0 8px rgba($from-color-success, 0.05),
        0 0 0 16px rgba($from-color-success, 0.04),
        0 0 0 32px rgba($from-color-success, 0.03),
        0 0 0 64px rgba($from-color-success, 0.03);
    }
    .material-icons-round:after {
      background: linear-gradient(
        to right bottom,
        $from-color-success,
        $to-color-success
      );
    }
  }
  &.danger {
    box-shadow: 0 0 0 0 rgba($to-color-danger, 0.4);
    background: linear-gradient(
      to right bottom,
      $from-color-danger,
      $to-color-danger
    );
    &:focus {
      box-shadow: 0 0 0 4px rgba($to-color-danger, 0.4);
    }
    .material-icons-round:after,
    &.round:active {
      box-shadow: 0 0 0 8px rgba($from-color-danger, 0.05),
        0 0 0 16px rgba($from-color-danger, 0.04),
        0 0 0 32px rgba($from-color-danger, 0.03),
        0 0 0 64px rgba($from-color-danger, 0.03);
    }
    .material-icons-round:after {
      background: linear-gradient(
        to right bottom,
        $from-color-danger,
        $to-color-danger
      );
    }
  }
  &.info {
    box-shadow: 0 0 0 0 rgba($to-color-info, 0.4);
    background: linear-gradient(
      to right bottom,
      $from-color-info,
      $to-color-info
    );
    &:focus {
      box-shadow: 0 0 0 4px rgba($from-color-info, 0.25);
    }
    .material-icons-round:after,
    &.round:active {
      box-shadow: 0 0 0 8px rgba($from-color-info, 0.05),
        0 0 0 16px rgba($from-color-info, 0.04),
        0 0 0 32px rgba($from-color-info, 0.03),
        0 0 0 64px rgba($from-color-info, 0.03);
    }
    .material-icons-round:after {
      background: linear-gradient(
        to right bottom,
        $from-color-info,
        $to-color-info
      );
    }
  }
  user-select: none;
  border: none;
  background: transparent;
  height: 46px;
  min-width: 46px;
  margin: 3px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 2px;
  padding-right: 16px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  background: linear-gradient(to right bottom, $from-color, $to-color);
  border-radius: 8px;
  transition: color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  outline: none;
  box-shadow: 0 0 0 0 rgba($from-color, 0.4);
  span {
    display: inline-block;
    z-index: 1;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: rgba($elements-background-color, 0.5);
  }
  &.round {
    padding: 0;
    border-radius: 50%;
    z-index: 1;
    overflow: unset;
    &:after {
      border-radius: 50%;
    }
    &:active {
      box-shadow: 0 0 0 8px rgba($from-color, 0.05),
        0 0 0 16px rgba($from-color, 0.04), 0 0 0 32px rgba($from-color, 0.03),
        0 0 0 64px rgba($from-color, 0.03);
      .material-icons-round {
        color: $elements-background-color;
        &:after {
          transform: scale3d(1.2, 1.2, 1);
          opacity: 1;
        }
      }
    }

    .material-icons-round:after {
      box-shadow: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 6px;
    background-color: $elements-background-color;
    z-index: -1;
  }
  .material-icons-round {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    position: relative;
    transition: color 150ms ease-in-out;
    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin-top: -18px;
      margin-left: -18px;
      transform: scale3d(0, 0, 1);
      opacity: 0;
      transition: transform 200ms ease-in-out, opacity 150ms ease-in-out;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: linear-gradient(to right bottom, $from-color, $to-color);
      z-index: -1;

      box-shadow: 0 0 0 8px rgba($from-color, 0.05),
        0 0 0 16px rgba($from-color, 0.04), 0 0 0 32px rgba($from-color, 0.03),
        0 0 0 64px rgba($from-color, 0.03);
    }
  }
  &:focus {
    box-shadow: 0 0 0 4px rgba($from-color, 0.4);
  }
  &:hover,
  &:focus {
    .material-icons-round {
      color: $elements-background-color;
      &:after {
        transform: scale3d(1, 1, 1);
        opacity: 1;
      }
    }
  }
  &:active {
    box-shadow: 0 0 0 4px rgba($from-color, 0.4);
    .material-icons-round {
      color: $elements-background-color;
      &:after {
        transform: scale3d(3, 3, 1);
        opacity: 1;
      }
    }
  }
}
.todos {
  margin-left: 0;
  margin-right: 0;
  list-style: none;
  .todo {
    $border-size: 2px;
    $border-radius: 30px;
    list-style: none;
    position: relative;
    display: flex;
    padding: $border-size + 1px;
    overflow: hidden;
    border-radius: $border-radius;
    background: linear-gradient(to right bottom, $from-color, $to-color);
    align-items: center;
    z-index: 1;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    &:after {
      content: "";
      position: absolute;
      top: $border-size;
      right: $border-size;
      bottom: $border-size;
      left: $border-size;
      border-radius: $border-radius - $border-size;
      background-color: $elements-background-color;
      z-index: -1;
    }
    label,
    .label {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 15px;
      position: relative;
      // background-color: pink;
      min-height: 52px;
      cursor: pointer;
      span {
        display: block;
        margin: 5px 10px;
      }
      input {
        display: block;
        width: 1px;
        height: 1px;
        position: absolute;
        top: -10px;
        left: -10px;
      }
      i {
        color: $text-color;
        z-index: 0;
        position: relative;
        transition: color 200ms ease-in-out;
        &:after {
          content: "";
          z-index: -1;
          position: absolute;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: linear-gradient(to right bottom, $from-color, $to-color);
          top: 50%;
          left: 50%;
          margin-top: -16px;
          margin-left: -16px;
          transform: scale3d(0, 0, 1);
          opacity: 0;
          transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
        }
      }
      &:focus-within,
      &:hover {
        i {
          color: $elements-background-color;
          &:after {
            transform: scale3d(1, 1, 1);
            opacity: 1;
          }
        }
      }
      // &:active i:after {
      //   transform: scale3d(1.3, 1.3, 1);
      //   opacity: 1;
      // }
    }
    &.done {
      background: linear-gradient(
        to right bottom,
        $from-color-success,
        $to-color-success
      );
      label,
      .label {
        span {
          text-decoration: line-through;
        }
        i:after {
          background: linear-gradient(
            to right bottom,
            $from-color-success,
            $to-color-success
          );
        }
      }
    }
  }
}

.tag {
  display: inline-block;
  font-size: 0.8rem;
  background-color: $elements-background-color;
  padding: 2px 4px 2px 10px;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    background-color: $elements-background-color;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
    &:hover, &:focus {
      outline: none;
      background-color: darken($elements-background-color, 5%);
    }
    &:active {
      background-color: darken($elements-background-color, 10%);
    }
    i {
      font-size: inherit;
    }
  }
}